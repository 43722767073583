<template>

    <div class="bigBox">
        <div class="ptxxTopBox">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/Icon(1)_1686298472325.png" class="backIcon" @click="toBack">
            <p class="ptxxTopBoxTitle">平台消息</p>
        </div>
        <div style="height:.88rem"></div>


        <div class="ptxxList" v-if="xxfrom[0]">
            <div class="ptxxItem" v-for="(item) in xxfrom" :key='item.id'>
                <img :src="item.img" class="ptxxItemAvatar">
                <div class="ptxxItemTxtBox" >
                    {{item.txt}}
                </div>
            </div>


        </div>

        <div v-else class="noData">
            暂无消息
        </div>



    </div>



</template>

<script>
import Cookies from 'js-cookie'
import {getListColumn,getChildColumn,getArticleAndProject,getListBanner,getlistOnHomepage,getlistOnHomepage2,sms,phoneRegister} from "@/api/index"
import { Loading ,Message } from 'element-ui';
import store from '@/store'

export default {

    data(){
        return {
            load : null,
            xxfrom: [
                {   
                    'id':'111',
                    'img':'https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/企业微信截图_16863770752003_1686377167681.png',
                    'txt':'为保障您的权益，根据有关规则和制度，特提供本风险提示书。请您认真阅读。当您完成注册并在本《风险提示书》前方“□”打√时，即表明您已充分了解并知晓挂牌、认购或交易的风险，并承诺独立承担该风险'
                },
            ],
            wjsToken: null,
            wjsUserInfo: null,
        }
    },
    methods:{

        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },

        toBack(){
            this.$router.back(-1)
        },

            

    },
    mounted(){

        this.wjsToken =  Cookies.get('wjsToken') || null;
        this.wjsUserInfo =  Cookies.get('wjsUserInfo') || null;
        this.wjsUserInfo = JSON.parse(this.wjsUserInfo);

        console.log(this.wjsToken)
        console.log(this.wjsUserInfo)

        this.showLoading()
        this.load && this.load.close();
    },
    filters: {
        movieTypeFilters: function (value) {
            if (!value) return ''
                switch(value){
                case 1:
                    return "院线电影"
                    break;
                case 2:
                    return "网络电影"
                    break;
                default:
                    return "error"
            }
        },
        shootStatusFilters: function (value) {
            if (!value) return ''
                switch(value){
                case 1:
                    return "未上映"
                    break;
                case 2:
                    return "已上映"
                    break;
                default:
                    return "error"
            }
        },
        dataFilter:function(val){
            if (!val) return ''
            return val.substring(0,10)
        }
    },

}




</script>





<style lang="scss" scoped>
.bigBox{
    width: 100%;
    height: 100%;
    background-color: #fff;

    .ptxxTopBox{
        height: .88rem;
        width: 100%;
        position: relative;
        position: fixed;
        top: 0;
        z-index: 999;
        background-color: #fff;
        .backIcon{
            height: .48rem;
            width: .48rem;
            position: absolute;
            top: .2rem;
            left: .4rem;
        }
        .ptxxTopBoxTitle{
            font-size: .34rem;
            color: #343434;
            text-align: center;
            line-height: 0.88rem;
        }
    }


    .ptxxList{
        
        width: 100%;
        .ptxxItem{
            padding: .22rem .32rem;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            .ptxxItemAvatar{
                width: .68rem;
                height: .68rem;
                object-fit: cover;
                border-radius: 50%;
                margin-right: .22rem;
            }
            .ptxxItemTxtBox{
                padding: .24rem .24rem;
                box-sizing: border-box;
                background: #F4F4F4;
                border-radius: .2rem;
                font-size: .26rem;
                color: #343434;
                line-height: .4rem;
                text-align: justify;
                p{
                    font-size: .26rem;
                    color: #343434;
                    line-height: .4rem;
                    text-align: justify;
                }
                span{
                    font-size: .26rem;
                    color: #343434;
                    line-height: .4rem;
                    text-align: justify;
                }
            }
        }
    }


}

.noData{
    font-size: .4rem;
    text-align: center;
    line-height: 3rem;
}


</style>